<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
     <v-layout v-if="login != true" wrap justify-start :style="' background-color: #766BC0 '">
    <v-flex xs12 py-2 :style="' color: white '">
    <v-layout  wrap justify-space-around class="hidden-sm-and-down">
            <v-flex
              xs12 md9 lg12
              align-self-center
              class="text-left pl-2 nsbold"
              style="font-size: 18px; font-weight: 500"
              >St.Vincent Boarding House 
            </v-flex>
          </v-layout>
    </v-flex>
     </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-card elevation="0">
        <v-layout wrap pb-5 px-3 pt-2>
          <v-flex xs12>
            <h1 class="nsbold" style="font-size:28px;">Privacy Policy</h1>
          </v-flex>
        </v-layout>
        <v-layout wrap px-3>
          <v-flex xs12 class="justify-space-around">
            <p class="nsregular" style="font-size:18px;">
              Our privacy policy will help you understand what information we
              collect at St.Vincent Boarding House, how St.Vincent Boarding House
              House uses it, and what choices <br/> you have. St.Vincent Boarding House
              House built the St.Vincent Boarding House app as a free app. This
              SERVICE is provided by St.Vincent Boarding House at no cost <br/>  and is
              intended for use as is. Our privacy policy will help you
              understand what information we collect at St.Vincent Boarding House
              House, how St.Vincent Boarding House <br/> House uses it, and what choices you
              have. St.Vincent Boarding House built the St.Vincent Boarding House
              House app as a free app. This SERVICE is provided by St<br/>  Vincent
              Boarding House at no cost and is intended for use as is. If you
              choose to use our Service, then you agree to the collection and
              use of information in<br/>  relation with this policy. The Personal
              Information that we collect are used for providing and improving
              the Service. We will not use or share your information <br/> with anyone
              except as described in this Privacy Policy. The terms used in this
              Privacy Policy have the same meanings as in our Terms and
              Conditions, which is accessible in our website, unless otherwise
              defined in this Privacy Policy.
            </p>
          </v-flex>
        </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import store from "../store"
export default {
  computed:
  {
    login()
    {
      return store.state.isLoggedIn
    }
  }
}
</script>